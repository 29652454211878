import React, { useState, useEffect } from 'react';
import WetFart from '../../audio/wettt.mp3';
import StonieAnthem from '../../audio/StonieAnthem.mp3'
import axios from "axios"
import Address from '../address/Address';
import './password.style.scss';
import { GiSpeaker, GiSpeakerOff } from 'react-icons/gi'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';


const Password = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [song] = useState(new Audio(StonieAnthem))
  const [fartSound] = useState(new Audio(WetFart))
  const [yourPassword, setYourPassword] = useState('');
  const [yourWallet, setYourWallet] = useState('');
  const [myWalletAddress, setMyWalletAddress] = useState('')
  const [isCorrect, setIsCorrect] = useState(false)
  const [isOpen, setIsOpen] = useState(false);



  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const name = props.name


////////////////////////////// MAIN AUDIO //////////////////////////////

  let toggleAudio = () => {

    if (isPlaying) {
      song.pause()
    } else {
      song.play()
    }
    setIsPlaying(!isPlaying)
  }

////////////////////////////// POST WALLET //////////////////////////////

  const postWallet = async (walletAddress) => {
    try {
      const response = await axios.post('https://littlestonies-api.onrender.com/api/wallet', {
        walletAddress,
      })
    } catch (err) {
      console.log(err.response.data)
    } finally {
      return (
        togglePopup()
      )
    }
  }

////////////////////////////// HANDLE PW SUBMIT //////////////////////////////

  const handleSubmit = async (event) => {
    event.preventDefault()
    let password = ''
    if(name === 'cnflcts'){
      password = 'luh schmonky'
      var passwordValue = document.getElementById('password').value;
    }
    if(name === 'home'){
      password = 'in the clouds'
      var passwordValue = document.getElementById('password').value;
    }
    if(name === 'wan'){
      password = 'yodie gang'
      var passwordValue = document.getElementById('password').value;
    }
    if(name === 'valopa'){
      password = 'kat piss'
      var passwordValue = document.getElementById('password').value;
    }

    if(passwordValue.toLowerCase() === password.toLowerCase()){
      setIsCorrect(true)
    }else{
      if(isPlaying){
        toggleAudio()
        fartSound.play()
        toggleAudio()
      }else{
        fartSound.play()
      }
    }

    // reset wallet address input field
    document.getElementById('walletAddress').value = '';
  }




  const handleWalletSubmit = async (event) => {
    event.preventDefault()
    var walletAddress = document.getElementById('walletAddress').value;
    setMyWalletAddress(walletAddress)
    postWallet(walletAddress)
  }

  return (

    <div className="top__level">
      <div className="logo__container">
        <div className="logo">
          <div><img src='images/logo.png' alt="the little stonies logo" /></div>
        </div>
      </div>
      {!isOpen ?
        <>
        {!isCorrect ? 
          <>
            <p className="message">find somebody that knows somebody</p>
            <form className="password__form" action="" onSubmit={handleSubmit}>
              <div className="password__form-div">
                <input className="password__form-input" type="text" name="user_name" id='password' onChange={(e) => setYourPassword(e.target.value)} required placeholder="ENTER PASSWORD"/>
                <button onClick={handleSubmit} className='passwordButton'>
                  <ChevronRightSharpIcon style={{ color: 'black' }}/>
                </button>
              </div>
            </form>
          </>
        :
        <React.Fragment key="wallet-form">
          <p className="message">Enter your wallet address</p>
          <form className="password__form" action="" onSubmit={handleWalletSubmit}>
            <div className="password__form-div">
              <input className="password__form-input" type="text" name="" id='walletAddress' onChange={(e) => setYourWallet(e.target.value)} required placeholder="WALLET ADDRESS"/>
              <button onClick={handleWalletSubmit} className='passwordButton'>
                  <ChevronRightSharpIcon style={{ color: 'black' }}/>
                </button>
            </div>
          </form>
        </React.Fragment>
        }
        
        </>
        :  <Address handleClose={togglePopup} alertText='Success' outcome='your wallet entry has been confirmed'  walletAddress={myWalletAddress}/>
        }

      <div className="legs__container">
        <img className="legs" src='images/legs.png' alt="" />
      </div>
      <div className="footer">
        <p className="footer__text"> <span className="confidential">CONFIDENTIAL </span> <span className="date">APRIL 2023</span> </p>
        <span className="timer-speaker__container">
          {/* <span className="timer">0:24:00:00</span> */}
          {isPlaying ? <GiSpeaker className="speaker" onClick={toggleAudio} /> : <GiSpeakerOff className="speaker" onClick={toggleAudio} />}
        </span>
      </div>
    </div >

  )
}

export default Password;