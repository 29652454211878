import React from 'react'
import './lost.css'

const Lost = () => {
  return (
    <div className='container'>
        <img className='logo' src='images/mapguy.png' alt="the little stonies logo" />
    </div>
  )
}

export default Lost