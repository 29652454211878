import Password from "./components/password/Password";
import { Routes, Route, Navigate } from "react-router-dom";
import Lost from "./components/Lost";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <script src="/JS/init.js" type="module" />
        <script src="/JS/init.js" type="text/javascript" />
      </Helmet>

      <Routes>
        <Route path="/cnflcts" element={<Password name="cnflcts" />} />
        <Route path="/home" element={<Password name="home" />} />
        <Route path="/wan" element={<Password name="wan" />} />
        <Route path="/valopa" element={<Password name="valopa" />} />
        <Route path="/lost" element={<Lost />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </div>
  );
}

export default App;
