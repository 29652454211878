import React from 'react';
import './address.style.scss';

import { GrFormClose } from 'react-icons/gr';

const Address = (props) => {
  return (
    <div className="address__container">

      <div className="outcome__container">
        <p className="outcome">{props.alertText}</p>
        <GrFormClose size={18} className="close" onClick={props.handleClose}/>
      </div>

      <p className="outcome__message">{props.outcome}</p>

      <p className="label">address</p>

      <form className="wallet__form" action="" >
        <div className="wallet__form-div">
          <input readOnly className="wallet__form-input" type="text" name="address" required placeholder={props.walletAddress}
          />
        </div>
      </form>
    </div>
  )
}

export default Address;